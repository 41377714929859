import React, { useEffect, useState } from 'react';
import {
  Grid,
  MenuItem,
  Chip,
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Controls } from '../components/controls/Controls';
import SettingsInputSwitcher from '../components/Settings/SettingsController/SettingsInputSwitcher';
import { useApiClient } from '../context/ApiClientContext';
import AccuroAppointmentTypeSetting from './integrations/Accuro/AppointmentTypeSetting/AccuroAppointmentTypeSetting';

const makeIntakePlanLabel = (refCode, moduleName) => `${refCode} [${moduleName}]`;

export default function EditSettingsOptions(props) {
  const {
    setValues,
    handleSaveClick,
    values,
    newSetting,
    refreshParent,
    setErrorMessage,
    setSuccessMessage,
    isCreateMode,
    contextType,
  } = props;

  const [intakePlans, setIntakePlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [qrCodeIntakePlan, setQrCodeIntakePlan] = useState({});
  const apiClient = useApiClient();
  const { siteId, customerId } = useParams();

  const booleanSettingList = [
    'accuroEnabled',
    'accuroPreBookedAutomationEnabled',
    'accuroSameDayAutomationEnabled',
    'accuroFilterByProviderIds',
    'notificationEnabled',
    'canEditReport',
    'useMedicalNameWhenReportingSymptoms',
    'consentPageLogoOn',
    'privacyPolicyEnabled',
    'disagreeButtonDisabled',
    'pushToEndpointEnabled',
    'sftpAutomationEnabled',
    'athenaEnabled',
  ];

  const logoSettingList = [
    'consentPageLogo',
    'thankYouPageLogo',
    'disagreePageLogo',
  ];

  const handleIntakePlansSelectionChange = (event) => {
    let newSelectedPlans = event.target.value;
    if (newSelectedPlans.includes('*')) {
      setSelectedPlans(intakePlans);
      setValues({ name: 'intakePlans', category: 'medical', value: '*' });
      return;
    }
    newSelectedPlans = newSelectedPlans.filter(Boolean);
    setSelectedPlans(newSelectedPlans);
    const intakePlanValue = newSelectedPlans.map((plan) => plan.id).join(',');
    setValues({ name: 'intakePlans', category: 'medical', value: intakePlanValue });
  };

  const handleQrCodeIntakePlanSelectionChange = (event) => {
    const selectedPlan = event.target.value;
    const selectedPlanValue = selectedPlan.id.toString();

    setQrCodeIntakePlan(selectedPlan);

    setValues({ name: 'qrCodeIntakePlan', category: 'medical', value: selectedPlanValue });
  };

  async function getIntakePlans() {
    try {
      const intakePlanList = (await apiClient.getIntakePlans()).data;
      return intakePlanList;
    } catch (error) {
      return [];
    }
  }

  async function fetchSiteData(id) {
    const site = await apiClient.getSite(id);
    return { settings: site.data.settings, defaultSettings: site.data.defaultSettings };
  }

  async function fetchCustomerData(id) {
    const customer = await apiClient.getCustomer(id);
    return customer.data.settings;
  }

  async function getIntakePlansAssignedToCustomerOrSite(type, settingName) {
    try {
      let setting = null;
      if (type === 'site') {
        const { settings, defaultSettings } = await fetchSiteData(siteId);
        setting = settings.find((s) => s.name === settingName);
        if (!setting) {
          setting = defaultSettings.find((s) => s.name === settingName);
        }
      } else {
        const settings = await fetchCustomerData(customerId);
        setting = settings.find((s) => s.name === settingName);
      }
      return setting ? setting.value : null;
    } catch (error) {
      setErrorMessage(`Failed to fetch ${settingName}`);
      return null;
    }
  }

  const getSelectedPlans = (intakePlanIds, intakePlanList) => {
    if (intakePlanIds[0] === '*') {
      return intakePlanList;
    }
    if (intakePlanIds[0] === '') {
      return [];
    }
    const plans = intakePlanList.filter((intakePlan) => (
      intakePlanIds.includes(intakePlan.id.toString())));
    return plans;
  };

  useEffect(() => {
    if (values.name === 'intakePlans' || values.name === 'accuroAppointmentType') {
      getIntakePlans().then((intakePlanList) => {
        setIntakePlans(intakePlanList);
        getIntakePlansAssignedToCustomerOrSite(contextType, 'intakePlans').then((intakePlansList) => {
          const intakePlanNames = intakePlansList.split(',');
          const plans = getSelectedPlans(intakePlanNames, intakePlanList);
          setSelectedPlans(plans);
        });
      });
    }

    if (values.name === 'qrCodeIntakePlan') {
      getIntakePlans().then((intakePlanList) => {
        setIntakePlans(intakePlanList);
        getIntakePlansAssignedToCustomerOrSite(contextType, 'qrCodeIntakePlan').then((intakePlan) => {
          const plans = getSelectedPlans([intakePlan], intakePlanList);
          setQrCodeIntakePlan(plans[0]);
        });
      });
    }
  }, [values.name]);

  const renderOption = () => {
    if (values.name === 'accuroAppointmentType') {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <AccuroAppointmentTypeSetting
              appointmentSetting={values.value
                ? JSON.parse(values.value) : [{ id: 0, name: 'default', intakeModuleId: null }]}
              selectedPlans={selectedPlans}
              refreshParent={refreshParent}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
            />
          </Grid>
        </Grid>
      );
    }
    if (values.name === 'intakePlans') {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Controls.Select
              sx={{ minWidth: '200px', maxWidth: '400px' }}
              label="Value"
              name="value"
              value={selectedPlans}
              onChange={handleIntakePlansSelectionChange}
              error={null}
              multiple
              renderValue={() => {
                if (selectedPlans.length === 0) {
                  return <em>None</em>;
                }
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedPlans?.map((value) => (
                      <Chip
                        key={value.id}
                        label={makeIntakePlanLabel(value.referenceCode, value.moduleName)}
                      />
                    ))}
                  </Box>
                );
              }}
            >
              <MenuItem key="All Intake Plans" value="*">
                All Intake Plans
              </MenuItem>
              {intakePlans.map((option) => (
                <MenuItem key={option.referenceCode} value={option}>
                  {makeIntakePlanLabel(option.referenceCode, option.moduleName)}
                </MenuItem>
              ))}
            </Controls.Select>
          </Grid>
          {!newSetting && (
            <Grid item>
              <Controls.Button
                text="Save"
                onClick={handleSaveClick}
              />
            </Grid>
          )}
        </Grid>
      );
    }
    if (values.name === 'qrCodeIntakePlan') {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <Controls.Select
              sx={{ minWidth: '200px', maxWidth: '400px' }}
              label="Value"
              name="value"
              value={qrCodeIntakePlan}
              onChange={handleQrCodeIntakePlanSelectionChange}
              error={null}
              renderValue={() => {
                if (!qrCodeIntakePlan) {
                  return <em>None</em>;
                }
                return (
                  <Chip key={qrCodeIntakePlan.id} label={qrCodeIntakePlan.referenceCode} />
                );
              }}
            >
              {intakePlans.map((option) => (
                <MenuItem key={option.uniqueName} value={option}>
                  {makeIntakePlanLabel(option.referenceCode, option.moduleName)}
                </MenuItem>
              ))}
            </Controls.Select>
          </Grid>
          {!newSetting && (
            <Grid item>
              <Controls.Button
                text="Save"
                onClick={handleSaveClick}
              />
            </Grid>
          )}
        </Grid>
      );
    }
    if (booleanSettingList.includes(values.name)) {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <SettingsInputSwitcher
              type="switch"
              setting={values}
              refreshParent={refreshParent}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              defaultOption={{ value: values.name === 'consentPageLogoOn' }}
              contextType={contextType}
            />
          </Grid>
        </Grid>
      );
    }
    if (values.name === 'accuroSendDays') {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <SettingsInputSwitcher
              type="toggle"
              setting={values}
              refreshParent={refreshParent}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              options={[
                { name: 'Mon', value: 1 },
                { name: 'Tues', value: 2 },
                { name: 'Wed', value: 3 },
                { name: 'Thurs', value: 4 },
                { name: 'Fri', value: 5 },
                { name: 'Sat', value: 6 },
                { name: 'Sun', value: 7 },
              ]}
              defaultOption={{ name: 'All', value: '' }}
              contextType={contextType}
            />
          </Grid>
        </Grid>
      );
    }
    if (logoSettingList.includes(values.name)) {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <SettingsInputSwitcher
              type="logoUpload"
              setting={values}
              setValues={setValues}
              refreshParent={refreshParent}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              isCreateMode={isCreateMode}
              contextType={contextType}
            />
          </Grid>
        </Grid>
      );
    }

    if (values.name.toLowerCase().includes('color') || values.name === 'primaryTheme') {
      return (
        <Grid container item spacing={2} direction="row" alignItems="center">
          <Grid item>
            <SettingsInputSwitcher
              type="colorPicker"
              setting={values}
              refreshParent={refreshParent}
              setErrorMessage={setErrorMessage}
              setSuccessMessage={setSuccessMessage}
              isCreateMode={isCreateMode}
              contextType={contextType}
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container item spacing={2} direction="row" alignItems="center">
        <Grid item>
          <SettingsInputSwitcher
            type="text"
            setting={values}
            setValues={setValues}
            refreshParent={refreshParent}
            setErrorMessage={setErrorMessage}
            setSuccessMessage={setSuccessMessage}
            isCreateMode={isCreateMode}
            contextType={contextType}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      {renderOption()}
    </Grid>
  );
}

EditSettingsOptions.propTypes = {
  setValues: PropTypes.func.isRequired,
  handleSaveClick: PropTypes.func.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  contextType: PropTypes.oneOf(['customer', 'site']).isRequired,
  newSetting: PropTypes.bool,
  refreshParent: PropTypes.func,
  setErrorMessage: PropTypes.func,
  setSuccessMessage: PropTypes.func,
  isCreateMode: PropTypes.bool,
};

EditSettingsOptions.defaultProps = {
  newSetting: false,
  refreshParent: null,
  setErrorMessage: null,
  setSuccessMessage: null,
  isCreateMode: false,
};
